<template>
	<div>
		<VApp>
			<VMain>
				<slot />
			</VMain>
			<CoreNotification />
		</VApp>
	</div>
</template>
